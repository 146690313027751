import { useContext } from 'react';

import Logo from '@/components/Logo';
import LottieLazyWrapper from '@/components/LottieLazyWrapper';
import { UploaderContext } from '@/context/Uploader';
import animationData from '@/public/lottiefiles/uploadingFiles.json';
import getBrandCookie from '@/utils/brandCookie/client';

import styles from './styles.module.scss';

export default function ProgressView() {
  const { preparingJobsImagesCount, preparingJobsPreparedImagesCount } =
    useContext(UploaderContext);

  return (
    <div className={styles.container}>
      <Logo brandCookie={getBrandCookie()} />
      <div>
        <p
          className={styles.title}
          data-testid="preparing-pictures"
        >{`We are preparing your photo${
          preparingJobsImagesCount > 1 ? 's' : ''
        }!`}</p>
        <LottieLazyWrapper
          width={300}
          height={300}
          options={{
            animationData,
          }}
        />
        <p className={styles.counter} data-testid="uploading-count">
          {`Processing your photo${
            preparingJobsImagesCount > 1 ? 's' : ''
          }: ${preparingJobsPreparedImagesCount}/${preparingJobsImagesCount}`}
        </p>
      </div>
      <p className={styles.subtitle}>
        {`Please stay on this page and don’t reload to make sure your photo${
          preparingJobsImagesCount > 1 ? 's' : ''
        } can
        be uploaded!`}
      </p>
      {/* This is to fix a scroll bug on mobile on safari */}
      <div className={styles.scrollWhiteDiv} />
    </div>
  );
}

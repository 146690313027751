import Cookies from 'js-cookie';

import { URL_PARAM_BRAND } from '@/constants';
import { Brand } from '@/types/brand';

export default function getBrandCookie() {
  const value = Cookies.get(URL_PARAM_BRAND);

  switch (value) {
    case Brand.PICTA:
    case Brand.WALGREENS:
    case Brand.SHUTTERFLY:
      return value;
    default:
      return Brand.DEFAULT;
  }
}
